import type { AccessToken, AuthState, IDToken, UserClaims } from '@okta/okta-auth-js';
import Cookies from 'js-cookie';
import { action, makeAutoObservable, runInAction } from 'mobx';

import { ApplicationSetCookies } from '~/constants/ApplicationSetCookies';
import { Currencies } from '~/constants/currencies';
import { SentryHelper } from '~/helpers/Sentry/SentryHelper';
import { LocalStorageHelper } from '~/helpers/StorageHelper';
import { UserProfile } from '~/models/UserProfile';
import { OktaAuthClient } from '~/utils/oktaAuthClient';
import { createContext } from 'react';

interface AuthenticationResult {
    isAuthenticated: boolean;
    jwt?: string;
    userId?: string;
    tokenInfo?: { accessToken?: AccessToken; idToken?: IDToken };
    email: string;
}

const TRADING_PORTFOLIO_COOKIE_ID = 'tpid';

const WHISKEY_PORTFOLIO_COOKIE_ID = 'wpid';

export class ClientsideAuthentication {
    isAuthenticated = false;

    jwt?: string = undefined;

    tokenInfo?: AuthenticationResult['tokenInfo'];

    userId?: string;

    email = '';

    tradingPortfolioId?: string;

    firstName?: string;

    lastName?: string;

    loading = true;

    private handlingChange = false;

    constructor() {
        this.tradingPortfolioId = Cookies.get(TRADING_PORTFOLIO_COOKIE_ID);

        makeAutoObservable(this, {
            updateUserProfile: action.bound,
            clear: action.bound
        });

        OktaAuthClient.getInstance()
            .then((okta) => {
                // for preprod testing
                const override = LocalStorageHelper.getItem('jwt-override');
                if (override) {
                    this.jwt = override;
                    this.isAuthenticated = true;

                    const obj = okta.token.decode(override);
                    this.email = obj.payload?.sub;
                    this.userId = undefined;
                    this.firstName = 'jwt';
                    this.lastName = 'override';

                    if (obj.payload?.uid) this.userId = String(obj.payload.uid);
                    SentryHelper.setUser(this.userId);
                } else if (typeof window !== 'undefined') {
                    okta.authStateManager.subscribe((state: AuthState) => {
                        this.handleStateChange(state);
                    });
                    // Triggered when a token has been renewed
                    okta.tokenManager.on('renewed', () => {
                        this.handleStateChange(okta.authStateManager.getAuthState());
                    });

                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    okta.start();
                }
            })
            .catch((error) => {
                console.error(error);
                SentryHelper.captureException(error);
            });
    }

    public handleServerSideAuth(jwt: AccessToken) {
        this.isAuthenticated = true;
        if (typeof jwt.claims.uid === 'string') {
            this.userId = jwt.claims.uid;
        }
        this.jwt = jwt.accessToken;
        this.tokenInfo = { accessToken: jwt };
        this.email = jwt.claims.email || jwt.claims.sub || '';
        this.firstName = jwt.claims.given_name;
        this.lastName = jwt.claims.family_name;

        // consider still loading if we are missing initial data
        this.loading = !this.email || !this.firstName || !this.lastName;
    }

    private handleStateChange(newState?: AuthState | null) {
        if (this.handlingChange) return;
        this.handlingChange = true;

        const inner = async () => {
            const okta = await OktaAuthClient.getInstance();

            if (!okta.tokenManager) return;
            const { accessToken, idToken } = await okta.tokenManager.getTokens();
            const isAuthenticated = await okta.isAuthenticated();
            // isAuthenticated blocks the page render, resolve as much as we can now
            runInAction(() => {
                this.isAuthenticated = isAuthenticated;
                this.userId = idToken?.claims.sub;
                SentryHelper.setUser(this.userId);
                if (idToken?.claims.email) {
                    this.email = idToken.claims.email;
                }

                this.jwt = accessToken?.accessToken;
                this.tokenInfo = { accessToken, idToken };
            });

            let user: UserClaims | undefined;
            if (isAuthenticated) {
                user = await okta.getUser();
            }

            runInAction(() => {
                this.loading = false;

                if (!this.isAuthenticated) {
                    okta.tokenManager.clear();
                }
                if (user) {
                    this.firstName = user.given_name;
                    this.lastName = user.family_name;
                }
            });

            if (newState) {
                this.setAuthCookies(newState);
            }
        };

        void inner().finally(() => {
            this.handlingChange = false;
        });
    }

    private setAuthCookies(authState: AuthState) {
        if (authState.isAuthenticated && authState?.accessToken?.accessToken && authState?.idToken?.claims.sub) {
            Cookies.set(ApplicationSetCookies.OktaAccessToken, authState.accessToken.accessToken);
            Cookies.set(ApplicationSetCookies.OktaSub, authState.idToken.claims.sub);

            const cookieCurrency = (typeof window !== 'undefined' && Cookies.get('localCurrency')) || Currencies.USD;
            SentryHelper.addNetworkingContext({
                userId: this.userId,
                currency: cookieCurrency,
                tradingPortfolioId: this.tradingPortfolioId,
                managedPortfolioId: `wp_${this.userId || ''}`
            });
        } else {
            Cookies.remove(ApplicationSetCookies.OktaAccessToken);
            Cookies.remove(ApplicationSetCookies.OktaSub);
        }
    }

    public updateUserProfile(payload: UserProfile) {
        this.firstName = payload?.firstName;
        this.lastName = payload?.lastName;
    }

    clear() {
        runInAction(() => {
            this.jwt = undefined;
            this.isAuthenticated = false;
            this.email = '';
            this.userId = undefined;
            this.firstName = undefined;
            this.lastName = undefined;
            this.userId = undefined;
        });

        this.setAuthCookies({ isAuthenticated: false });
        Cookies.remove(TRADING_PORTFOLIO_COOKIE_ID);
        Cookies.remove(WHISKEY_PORTFOLIO_COOKIE_ID);
    }

    /**
     * Stores the trading portfolio id in a cookie since the trading portfolio ID is not just
     * the prefixed user id. This allows the networking client to access this value when
     * authenticated without waiting for an async request to the server to reload this.
     */
    setTradingPortfolioId(tradingId: string) {
        this.tradingPortfolioId = tradingId;
        Cookies.set(TRADING_PORTFOLIO_COOKIE_ID, tradingId);
    }

    static setWhiskeyPortfolioId(whiskeyId: string) {
        Cookies.set(WHISKEY_PORTFOLIO_COOKIE_ID, whiskeyId);
    }
}

export const ClientsideAuthenticationContext = createContext<ClientsideAuthentication | null>(null);
